/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap');

@font-face {
  font-family: '851H-kktt';
  src: url('./851H-kktt.woff') format('woff');
}

:root {
  --line-color: #68cfff;
  --line-end-color: #0070a4;
  --blueback-color: #1ffbff8a;
  --background-color: #0a0c44;
  --litter-color:#ffffff;
  --litter-shadow-color:hsl(185, 100%, 84%);
  --min-width:95vw;
  /* --img-min-width:320px; */
  --img-min-width:170px;
  --calc-width:calc(100vw - 180px);
}


html {
    font-size: 62.5%;
    /* min-width: var(--min-width); */
    background-color: var(--background-color);
}

body {
    font-family: 'Raleway','Noto Sans JP', sans-serif;
    font-weight: 400;
    font-size: 1.6rem;
    font-display: swap;
    color: var(--litter-color);
    text-shadow: 
    /* 0 0 20px rgba(0, 247, 255, 0.5), */
    /* 0 0 10px rgba(0, 247, 255, 0.5), */
    0 0 7px rgba(0, 247, 255, 0.5),
    0 0 5px rgba(0, 247, 255, 1);
    margin: 0;
    padding: 0;
}



@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.header {
    text-align: center;
    backdrop-filter: blur(5px);
    width: 100%;
    /* min-width: var(--min-width); */
    background: rgba(255, 255, 255, 0.25);
}

.header > a {
    font-size: 1.4rem;
    color: inherit;
    margin: 0 2rem;
}

.top-page-header{
    width: 100%; /* 要素の横幅を100%に設定 */
    /* min-width: var(--min-width); */
    aspect-ratio: 224/75;
}
.top-page-header-img{
    /* background-image: url('header.png'); */
    /* background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url('header.png'); */
    background-position: center;
    height:100%;
    background: 
    linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 80%, #0a0c44b3 95%, #0a0c44 100%),
    url('header.png') no-repeat center top;
    background-size: cover;
    /* width:100%; */
}

.top-page-menu{
  width: 100%;
  right:0px;
  position: relative;
}




/* Title */
h1 {
    margin: 0;
    padding-top: 3rem;
    font-family: "Raleway";
    text-align: center;
    font-weight: 400;
    font-size: 5rem;
    line-height: 5rem;
    letter-spacing: 1rem;
    color: #12f7ff;
}



.top-h1{
  font-family: '851H-kktt', sans-serif;
  color: #ffffff;
  font-size: 7rem;
  padding-left: 1rem;
  padding-bottom: 10px;
  line-height: 8rem;
  letter-spacing: 0.2em;
}
.top-h2{
  /* src: url('./851H-kktt.woff') format('woff'); */
  font-family: '851H-kktt';
  font-size: 4rem;
  padding-left: 1rem;
  color: #ffffff;
  line-height: 5rem;
  text-align: center;
}

.top-div{
  /* src: url('./851H-kktt.woff') format('woff'); */
  font-family: '851H-kktt';
  font-size: 2.5rem;
  padding-left: 1rem;
  color: #ffffff;
  line-height: 3rem;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .top-h1{
    font-size: 4rem;
    padding-left: 1rem;
    padding-bottom: 10px;
    line-height: 5rem;
    letter-spacing: 0.1em;
  }
  .top-h2{
    font-size: 3rem;
    padding-left: 1rem;
    line-height: 5rem;
  }
  .top-div{
    font-size: 2rem;
    padding-left: 1rem;
    line-height: 2rem;
  }
}

@media screen and (max-width: 480px) {
  .top-h1{
    font-size: 3rem;
    padding-left: 1rem;
    padding-bottom: 10px;
    line-height: 3rem;
    letter-spacing: 0.01em;
  }
  .top-h2{
    font-size: 2rem;
    padding-left: 1rem;
    line-height: 5rem;
  }
  .top-div{
    font-size: 1.5rem;
    padding-left: 1rem;
    line-height: 1.5rem;
  }
}


/* Selector */
.selector-container {
    margin: 2rem 2rem;
    color: rgb(255, 255, 255);
}

.lives-container{
  margin: 0rem 0.5rem;
}

.selector-container > select {
    border-radius: 40px 0px 0px 40px;
    border: transparent;
    width: 360px;
    height: 27px;
    padding: 0.5rem 1rem;
    appearance:none;
    color: #000000;
    background: linear-gradient(250.6deg, #6A70FF 7.3%, rgba(255, 53, 53, 0.4) 98.63%);
}

.selector-container > select:active, .selector-container > select:focus, .selector-container > select:hover {
    outline: 0;
    border: none;
    cursor: pointer;
}

.selector-container > button {
    width: 98px;
    min-width: var(--min-width);
    height: 27px;
    border-radius: 0 40px 40px 0;
    background: #4473ff;
    border: none;
    border-left: 2px solid #fff;
    color: white;
    padding: 0.6rem 2rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
}

.selector-container > button:hover {
    color: #4473ff;
    background-color:#fff;
    transition: 0.3s all;
}

/* Results */
.results-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: var(--min-width);
    min-height: 300px;
    padding: 1rem;
    border-radius: 40px;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(20px);
    box-shadow: 4px 4px 13px 5px rgba(0,0,0,0.25);
}

.results-container p span {
    font-size: 3.4rem;
    color: #4473ff;
    margin-left: 2rem;
    font-weight: 600;
    letter-spacing: 0.1rem;
}

.card-container {
  margin-top: 4rem;
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(var(--img-min-width), 1fr)); */
  gap: 1rem;
}

@media screen and (min-width: 800px) {
  .card-container {
    grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
  }
}
@media screen and (max-width: 799px) {
  .card-container {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}

/* For responsive design */
@media only screen and (max-width: 500px) {
    h1 {
        margin-top: 3rem;
        font-size: 4rem;
        line-height: 3rem;
    }
    .selector-container > select {
        width: var(--min-width);
    }
    .results-container {
        width: var(--min-width);
    }
    .top-container {
      width: var(--min-width);
    }
    /* html{
      width:500px;
    } */
    .livelist .head .title {
      width:var(--calc-width);
    }
}

/* World Page */ 
.top-container {
  height: 100%;
  width:100%;
}

/* World Page */ 
.top-page-container {
    height: 100%;
    padding: 1rem 0.2rem;
    /* background: #0d266d; */
    background: var(--background-color);
    /* background: #0e5ff4; */
    background-size: 300% 300%;
    margin-bottom: 300px;
}




.card {
  display: flex;
  justify-content: center;
  /* flex-direction: column; カード内の要素を縦に配置 */
  /* align-items: center; カード内の要素を中央に配置 */
  background: rgba(105, 105, 105, 0.26);
  backdrop-filter: blur(20px);
  /* box-shadow: 4px 4px 13px 5px rgba(0, 0, 0, 0.641); */
  overflow: hidden;
  /* width: 100%;  */
  /* height:650px; */
}

.card .contents {
  width:100%;
}

.card .image-container {
    aspect-ratio: 16 / 9;
    overflow: hidden;
    position: relative;
}

/* .card .img {
  max-width: 100%;
  height: auto;
} */

.card .creater {
  font-size: 1.0rem;
  height:1.4rem;
  overflow: hidden; 
  text-overflow: ellipsis;
    display: -webkit-box; 
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
  /* position: absolute;
  bottom: 2px;
  left: 2px; */
  background: rgba(13, 102, 255, 0.5); /* 読みやすさのための半透明の背景 */
  color: white; /* テキストの色 */
  padding: 1px 4px; 
  margin: 1px 1px;
  /* border-radius: 0px 30px 30px 0px;  */
}


.card .publishedAt {
  font-size: 1.2rem;
  position: absolute;
  top: 2px;
  right: 2px;
  background: rgba(13, 102, 255, 0.5); /* 読みやすさのための半透明の背景 */
  color: white; /* テキストの色 */
  padding: 3px 8px; 
  border-radius: 30px 30px 30px 30px; /* 上左、上右、下右、下左の順に丸みを指定 */
}


.card img {
    width: 100%;
    min-width: var(--img-min-width);
    height: 100%;
    object-fit: cover;
}
.card h2 {
    font-weight: 100;
    font-size: 1.3rem;
    /* padding: 0rem 3rem; */
    margin:0rem 0rem 0rem 0.5rem ;
    height:7.6rem;
    width:95%;
    text-align: center;
    /* object-fit: cover; */
    /* white-space: nowrap; */
    overflow: hidden; 
    text-overflow: ellipsis;
    display: -webkit-box; 
    -webkit-line-clamp: 4; 
    -webkit-box-orient: vertical;
    color: var(--litter-color);
}

.card .creater-published-container{
  flex-direction:row;
  display: flex;
  justify-content: space-between;
  /* height:2.5rem; */
}




.card .creater p{
  margin:0.25rem 0rem 0rem 1.5rem ;
  letter-spacing: 0.1rem;
}



.card .published p{
  font-size: 15px;
  font-weight: 300;
  margin:0.25rem 0.5rem 0rem 1.5rem ;
  /* margin-top: 0; */
  padding-top: 0;
}


/* 新しいスタイルを追加 */
.card:nth-child(odd) {
  grid-column: auto;
  grid-row: auto;
}
.card:nth-child(even) {
  /* 偶数番目のカードに対して左上に詰めるスタイル */
  grid-column: auto;
  grid-row: auto; /* 自動配置を使用して上に詰める */
}

.toggle{
  align-items: center;
  /* display: flex; */
  padding-bottom: 5px;
  flex-direction: column;
  justify-content: flex-start;
}

.toggle label{
  font-size: 1.5rem;
}

.toggle-nokirinuki,
.toggle-modal,
.toggle-sortkirinuki{
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}


.livelist{
  background:var(--background-color)
}
.livelist .container {
  position: relative;
}

.livelist .container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right,  var(--line-color), var(--line-end-color));
}

.livelist .head {
  display: flex; /* Flexコンテナを定義 */
  width:100%;
  /* align-items: center; */
  border-top: 4px solid var(--line-color);
  padding-bottom: 10px;
  /* position: relative; */
  /* height: 170px; */
}

.livelist .head a { 
  height: 70px;
}

.livelist .head img {
  width: 120px;
  height: 70px;
  object-fit: cover;
  border-right: 5px solid var(--line-color); /* 右側のボーダーを設定 */
  border-bottom: 5px solid var(--line-color); /* 下側のボーダーを設定 */
  border-left: 5px solid var(--line-color); /* 左側のボーダーを設定 */
  /* padding: 5px; */
  /* margin-left: 2px; */
  /* position: absolute; */

}

.livelist .head .title {
  height: 2.2em;
  margin-left: 15px;
  margin-top: 5px;
}

.livelist .head  h2 {
  font-weight: 100;
  font-size: 16px;
  /* padding: 0rem 3rem; */
  margin:0rem;
  height:7rem;
  overflow: hidden;
  text-overflow: ellipsis; /* テキストが省略されると "..." が表示されます */
  /* white-space: nowrap; */
  width: 100%; /* コンテナの幅を調整してください */
  max-height: 2.2em; /* 最大の高さを2行分に設定（必要に応じて調整） */
  line-height: 1.1em; /* 行の高さを調整してください */
  display: -webkit-box; 
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;
  color: var(--litter-color);
  text-shadow: 
    0 0 20px rgba(0, 247, 255, 0.5), /* 青色の光 */
    0 0 10px rgba(0, 247, 255, 0.5), /* 青色の光 */
    0 0 7px rgba(0, 247, 255, 0.5), /* 青色の光 */
    0 0 5px rgba(0, 247, 255, 1);
}

.livelist .head .liver_name {
  font-size: 18px;
  background: rgba(13, 102, 255, 0.5); /* 読みやすさのための半透明の背景 */
  color: white; /* テキストの色 */
  padding: 3px 8px; 
  border-radius: 30px 30px 30px 30px; /* 上左、上右、下右、下左の順に丸みを指定 */
}

.livelist .head .name_publishedAt {
  display: flex;
  margin-left:2px;
}

.livelist .head .publishedAt {
  font-size: 16px;
  margin-left:10px;
  margin-top:4px;
}

/* .livelist .head .head_image{
  position: absolute;
} */

.livelist .head #elementA{
  position: relative;
  z-index: 16;
  white-space: nowrap;
  overflow: hidden;
}

.livelist .head #elementD{
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  bottom: 2px;
  left: 2px;
  background: rgba(13, 102, 255, 0.5); /* 読みやすさのための半透明の背景 */
  color: white; /* テキストの色 */
  padding: 3px 8px; 
  border-radius: 30px 30px 30px 30px; /* 上左、上右、下右、下左の順に丸みを指定 */
  z-index: 1;
}



.livelist .kirinukiList{
  display: flex; /* Flexコンテナを定義 */
  width:100%;
  align-items: center; /* 要素を垂直方向の中央に配置 */
  overflow-x: auto; /* 横方向のスクロールを有効にする */
  white-space: nowrap; /* アイテムを折り返さないようにする */
  background: linear-gradient(to right,  var(--line-color), var(--line-end-color));;
  margin-left: 5px;
  margin-bottom: 20px;

}
.livelist .kirinukiList img {
  width: 240px;
  height: 140px;
  object-fit: cover;
}

.livelist .kirinuki{
  position: relative;
  margin: 7px 7px 4px 7px;
  flex: 0 0 auto;
  padding-bottom: 0px;
}

.livelist .kirinuki .new {
  position: absolute;
  /* bottom: 10px; */
  top: -4px;
  left: -4px;
  
  
}

.new-label {
  background: rgba(255, 247, 0, 0.812); /* 読みやすさのための半透明の背景 */
  padding: 3px 8px; 
  border-radius: 30px 30px 30px 30px; /* 上左、上右、下右、下左の順に丸みを指定 */
  overflow: hidden; 
  text-overflow: ellipsis;
  display: -webkit-box; 
  -webkit-line-clamp: 1; 
  -webkit-box-orient: vertical;
  font-weight: 100;
  font-size: 1.5rem;
  /* margin-left: 8px; */
  color: #000000; /* 赤色 */
  text-shadow: none;
  font-weight: bold;
}

.livelist .kirinuki .creater {
  position: absolute;
  bottom: 5px;
  left: 2px;
  background: rgba(13, 102, 255, 0.6); /* 読みやすさのための半透明の背景 */
  color: white; /* テキストの色 */
  padding: 3px 8px; 
  border-radius: 30px 30px 30px 30px; /* 上左、上右、下右、下左の順に丸みを指定 */
  overflow: hidden; 
  text-overflow: ellipsis;
  display: -webkit-box; 
  -webkit-line-clamp: 1; 
  -webkit-box-orient: vertical;
  font-weight: 100;
  font-size: 1.0rem;
}

.livelist .kirinuki .duration {
  position: absolute;
  top: 3px;
  right: 1px;
  background: rgba(0, 0, 0, 0.600); /* 読みやすさのための半透明の背景 */
  color: white; /* テキストの色 */
  padding: 3px 8px; 
  border-radius: 30px 30px 30px 30px; /* 上左、上右、下右、下左の順に丸みを指定 */
  overflow: hidden; 
  text-overflow: ellipsis;
  display: -webkit-box; 
  -webkit-line-clamp: 1; 
  -webkit-box-orient: vertical;
  font-weight: 100;
  font-size: 1.3rem;
}


/* Loading */
.loading {
    color: #fe5c5c;
    font-size: 90px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 72px auto;
    position: relative;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  }
  @-webkit-keyframes load6 {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @keyframes load6 {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @-webkit-keyframes round {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes round {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}






.lead{
  margin:20px 0 0 0;
}

.btn-block{
  width:200px;  
  padding: 30px;
}

.menu-button{
  font-size:12px;
}




/*丸の拡大*/
.circle-bg{
  position: fixed;
  z-index:3;
  /*丸の形*/
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ffffff;
  /*丸のスタート位置と形状*/
  transform: scale(0);/*scaleをはじめは0に*/
  right:-50px;
    top:-50px;
  transition: all .6s;/*0.6秒かけてアニメーション*/
}

.circle-bg.circleactive{
transform: scale(50);/*クラスが付与されたらscaleを拡大*/
}



/*ナビゲーションの縦スクロール*/
#g-nav-list{
  display: none;/*はじめは表示なし*/
  /*ナビの数が増えた場合縦スクロール*/
  position: fixed;
  z-index: 10; 
  width: 100%;
  height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}


/*アクティブになったエリア*/
#g-nav.panelactive{
  position:fixed;
  z-index: 11;
  top: 30px;
  right:-150px;
  width:500px;
  height: 100vh;
}

/*クラスが付与されたら出現*/
#g-nav.panelactive #g-nav-list{
   display: block; 
}


/*ナビゲーション*/
#g-nav ul {
opacity: 0;/*はじめは透過0*/
  /*ナビゲーション天地中央揃え※レイアウトによって調整してください。不必要なら削除*/
  position: absolute;
  z-index: -1;
  top:18%;
  right:10px;
  transform: translate(-50%,-50%);
}

/*背景が出現後にナビゲーションを表示*/
#g-nav.panelactive ul {
  opacity:1;
}

/* 背景が出現後にナビゲーション li を表示※レイアウトによって調整してください。不必要なら削除*/
#g-nav.panelactive ul li{
animation-name:gnaviAnime;
animation-duration:1s;
animation-delay:.2s;/*0.2 秒遅らせて出現*/
animation-fill-mode:forwards;
opacity:0;
}
@keyframes gnaviAnime{
0% {
opacity: 0;
}
100% {
opacity: 1;
}
}

#g-nav li{
text-align: center; 
list-style: none;
}

#g-nav li a{
  color: #333;
  text-decoration: none;
  padding:10px;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: bold;
  font-size: 30px;
}

#about,#contact{
  font-size: 15px;
  opacity: 0;
  position: absolute;
  z-index: 15;
  top:120px;
  right:30px;
  width: 300px;
  transform: translate(-50%,-50%);
  color: #000000;
  animation-name:menuAnime;
  animation-duration:1s;
  animation-delay:.2s;/*0.2 秒遅らせて出現*/
  animation-fill-mode:forwards;
}

.menu-back {
  opacity: 0;
  position: absolute;
  top:40%;
  right:40%;
  border-radius: 15px;
  z-index: 18;
  width: 70px; /* ボタンの幅 */
  height: 30px; /* ボタンの高さ */
  cursor: pointer;
  animation-name:menuAnime;
  animation-duration:1s;
  animation-delay:.2s;/*0.2 秒遅らせて出現*/
  animation-fill-mode:forwards;
  background-color: var(--blueback-color); /* クロスの色 */
  color: var(--background-color);
  text-align: center;
  align-items: center;
}

.menu-back p{
  top:0;
  margin: 5px;
  font-weight: 500;
}



@keyframes menuAnime{
0% {
opacity: 0;
}
100% {
opacity: 1;
}
}



/*========= ボタンのためのCSS ===============*/
.openbtn5{
  /* position:fixed; */
  position: absolute;
  top: 10px;
  right: 2%;
  background:var(--background-color);
  cursor: pointer;
    width: 50px;
    height:50px;
  border-radius: 5px;
  border: 1px solid #ffffff ;
  z-index: 16;
}

.openbtn5:hover{
  background: var(--blueback-color);
}

.openbtn5 span{
  display: inline-block;
  transition: all .4s;/*アニメーションの設定*/
  position: absolute;
}

.openbtn5 span:nth-of-type(1),
.openbtn5 span:nth-of-type(3) {
    height: 2px;
    background: #fff;
    width: 62%;
    left: 10px;
 }

 .openbtn5 span:nth-of-type(1) {
  top:13px; 
}

.openbtn5 span:nth-of-type(2) {
  top:19px;
  left:8px;
  font-size:12px;
  text-transform: uppercase;
  color: #fff;
}

.openbtn5 span:nth-of-type(3) {
  top:36px;
}


.openbtn5.active span:nth-of-type(1) {
  top: 18px;
  left: 18px;
  transform: translateY(6px) rotate(-45deg);
  width: 30%;
}

.openbtn5.active span:nth-of-type(2) {
  opacity: 0;
}

.openbtn5.active span:nth-of-type(3){
  top: 30px;
  left: 18px;
  transform: translateY(-6px) rotate(45deg);
  width: 30%;
}


#page-top a{
  display: flex;
  justify-content:center;
  align-items:center;
  background:var(--background-color);
  opacity:0.8;
  border-radius: 5px;
  border: 1px solid #ffffff ;
  width: 50px;
  height: 50px;
  color: #fff;
  text-align: center;
  text-transform: uppercase; 
  text-decoration: none;
  font-size:1.6rem;
  transition:all 0.3s;
}

#page-top a:hover{
  background: var(--blueback-color);
}

/*リンクを右下に固定*/
#page-top {
  position: fixed;
  right: 2%;
  bottom:10px;
  z-index: 16;
    /*はじめは非表示*/
  opacity: 0;
  transform: translateX(100px);
}

/*　左の動き　*/

#page-top.LeftMove{
  animation: LeftAnime 0.5s forwards;
}

@keyframes LeftAnime{
  from {
    opacity: 0;
  transform: translateX(100px);
  }
  to {
    opacity: 1;
  transform: translateX(0);
  }
}


/*　右への動き　*/
#page-top.RightMove {
  animation: RightAnime 0.5s forwards;
}

@keyframes RightAnime {
  from {
    opacity: 1;
    transform: translateX(0px);
  }
  to {
    opacity: 0;
    transform: translateX(100px);
  }
}

.about-container{
  padding: 3% 10%;
  text-align: initial;
  font-size: large;
}

/* .about-container h1{
  padding-top: 0rem;
  margin-top: 0rem;
  line-height:4rem;
} */

.about-container h2{
  padding-top: 10%;
  font-size:x-large;
}
.about-sub-container{
  padding-left: 5%;
  padding-bottom: 20%;
  text-align: initial;
}

.about-sub-container a{
  color: var(--litter-color);
  padding-left: 2%;
  display: inline-block;
  word-wrap: break-word;
  max-width: 100%;
}

.about-sub-sub-container{
  padding-top: 5%;
}

/* .about-container p,a,h2{
  padding-left: 30px;
  padding-bottom: 20px;
  font-size: large;
} */



.policy-container{
  padding: 3% 5%;
  text-align: center;
}
.policy-container h1{
  padding-top: 0rem;
  margin-top: 0rem;
  line-height:4rem;
}
.policy-sub-container{
  padding: 3%;
  text-align: initial;
}

.policy-sub-container p{
  padding-left: 3%;
  padding-bottom: 2%;
}

.no_videos{
  height:300px
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-content {
  position: relative;
  width: 80%; /* コンテンツ幅を画面幅の80%に */
  max-width: 1280px; /* 最大幅を640pxに設定 */
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* スマートフォンなど小さなデバイスでのスタイル調整 */
@media (max-width: 768px) {
  .lightbox-content {
    width: 95%; /* より小さな画面でコンテンツ幅を広げる */
    padding: 5px; /* パディングを少し減らす */
  }
}

.lightbox-content iframe {
  width: 100%; /* 幅をコンテナに合わせる */
  height: auto; /* 高さを自動調整 */
  aspect-ratio: 16 / 9; /* アスペクト比を16:9に保持 */
}



.lightbox-content button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


/* ライトボックス内の動画コンテナ */
.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9のアスペクト比 */
}

.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}